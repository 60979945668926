<template>
    <!-- 修改密码 -->
    <div>
        <el-form
			:model="form"
            :rules="rules"
			ref="pwdForm"
            label-width="120px">
            <!-- 当前密码 -->
            <el-form-item 
				label="当前密码："
				prop="pwd">
                <el-input 
					maxlength="20"
                    type="password" 
					v-model="form.pwd"
                    class="pwd_input"
                    size="small"
					clearable></el-input>
            </el-form-item>
            <!-- 新密码 -->
            <el-form-item 
				label="新密码："
				prop="new_pwd">
                <el-input 
					maxlength="20"
                    type="password" 
					v-model="form.new_pwd"
                    class="pwd_input"
                    size="small"
					clearable></el-input>
            </el-form-item>
            <!-- 确认新密码 -->
            <el-form-item 
				label="确认新密码："
				prop="check_pwd">
                <el-input 
                    maxlength="20"
					type="password" 
					v-model="form.check_pwd"
                    class="pwd_input"
                    size="small"
					clearable></el-input>
            </el-form-item>
            <!-- 密码强度 -->
            <el-form-item label="密码强度：">
                <!-- 小方块 -->
                <div class="cube">
                    <span :class="[
						{weak: pwd_strong <= 2 && pwd_strong >= 0},
						{middle: pwd_strong == 3},
						{strong: pwd_strong == 4},
					]">
					</span>
                    <span :class="[
						{middle: pwd_strong == 3},
						{strong: pwd_strong == 4},
					]">
					</span>
                    <span :class="[
						{strong: pwd_strong == 4},
					]">
					</span>
                </div>
            </el-form-item>
        </el-form>
        <!-- 提交区域 -->
        <div style="margin-top:70px">
            <el-button 
                type="warning" 
				@click="handleSubmit('pwdForm')"
                class="give_up_btn">
				<span>保存</span>
			</el-button>
            <el-button 
                type="warning" 
                plain
                class="give_up_btn">
				<span>取消</span>
			</el-button>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    data() {
		let validatePass = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入新密码'));
			} else {
				this.pwd_strong = this.checkStrong(value);
				if (this.form.check_pwd !== '') {
					this.$refs.pwdForm.validateField('check_pwd');
				}
				callback();
			}
		};
		let validatePass2 = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'));
			} else if (value !== this.form.new_pwd) {
				callback(new Error('两次输入密码不一致!'));
			} else {
				callback();
			}
		};
        return {
            form: {
                pwd: "",
                new_pwd: "",
                check_pwd: "",
            },
            rules: {
                pwd: [
                    { required: true, message: '请输入原密码', trigger: 'blur' }
                ],
                new_pwd: [
                    { required: true, validator: validatePass, trigger: 'blur' }
                ],
                check_pwd: [
                    { required: true, validator: validatePass2, trigger: 'blur' }
                ],
			},
			pwd_strong: -1,
        }
    },
    methods: {
		...mapActions([
			"handleChangePwd"
		]),
        checkStrong(val){ // 校验密码强度
			let count = 0;
			if(val.length < 6) return 0;
			if (/\d/.test(val)) count++;
			if (/[a-z]/.test(val)) count++;
			if(/[A-Z]/.test(val)) count++;
			if (/\W/.test(val)) count++;
			return count;
		},
		handleSubmit(formName){ // 提交修改
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.handleChangePwd({
						password: this.form.pwd,
						newPassword: this.form.new_pwd
					}).then((res) => {
						// this.$message({
						// 	showClose: true,
						// 	message: res.msg,
						// 	type: res.type
						// })
						this.$alert(res.msg, '提示', {
							confirmButtonText: '确定'
						})
						if(res.type == "success"){
							this.form = {
								pwd: "",
								new_pwd: "",
								check_pwd: "",
							}
							this.pwd_strong = -1;
						}
					})
				} else {
					return false;
				}
			});
		}
    },
}
</script>

<style scoped>
/* 输入框 */
.pwd_input{
    width: 50%;
}
/* 小方块样式 */
.cube>span{
    display: inline-block;
    width: 100px;
    height: 15px;
    border: 1px solid #ddd;
    border-radius: 3px;
    background-color: #f5f5f5;
    margin-right: 5px;
}
.cube>span.weak{
    background-color: #F56C6C;
}
.cube>span.middle{
    background-color: #E6A23C;
}
.cube>span.strong{
    background-color: #67C23A;
}
/* 上传按钮 */
.give_up_btn{
    width: 100px;
}
</style>
